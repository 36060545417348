// Import Bootstrap and its default variables
.bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }
/////////////////////////////////////////////////
.container {
	max-width: 960px;
  }
  
  /*
   * Custom translucent site header
   */
  
  .site-header {
	background-color: rgba(0, 0, 0, .85);
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
  }
  .site-header a {
	color: #8e8e8e;
	transition: color .15s ease-in-out;
  }
  .site-header a:hover {
	color: #fff;
	text-decoration: none;
  }
  
  /*
   * Dummy devices (replace them with your own or something else entirely!)
   */
  
  .product-device {
	position: absolute;
	right: 10%;
	bottom: -30%;
	width: 300px;
	height: 540px;
	background-color: #333;
	border-radius: 21px;
	transform: rotate(30deg);
  }
  
  .product-device::before {
	position: absolute;
	top: 10%;
	right: 10px;
	bottom: 10%;
	left: 10px;
	content: "";
	background-color: rgba(255, 255, 255, .1);
	border-radius: 5px;
  }
  
  .product-device-2 {
	top: -25%;
	right: auto;
	bottom: 0;
	left: 5%;
	background-color: #e5e5e5;
  }
  
  .home_bg-custom{

    background-image: url("./blog3.png");
    //background-image: url("./picViv2.jpeg");
    background-repeat: no-repeat;
    background-size:cover;

    border-bottom: 1px solid;
    box-shadow: 0px 0px 10px 10px #888888;	
    }
  
    .home_bg-custom-link:hover{
      color:crimson;
      
    }
  .home_skills-bg-custom{
    //background-image: url("./blog1.png");
    background-image: url("./picViv.jpg");
    background-repeat: no-repeat;
    background-size: contain;

    border-bottom: 1px solid;
box-shadow: 0px 0px 10px 10px #888888;	
  }
  .home_projects-bg-custom{
    background-image: url("./blog4.png");
    background-repeat: no-repeat;
    background-size: cover;

    border-bottom: 1px solid;
box-shadow: 0px 0px 10px 10px #888888;	
  }

  .home_projects-bg-custom-div{
    //width:70%;
  }
  /*
   * Extra utilities
   */
  
  .flex-equal > * {
	flex: 1;
  }
  @media (min-width: 768px) {
	.flex-md-equal > * {
	  flex: 1;
	}
  }
  