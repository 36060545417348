// Import Bootstrap and its default variables

body {
	padding-bottom: 20px;
  }
  
  .navbar {
	margin-bottom: 20px;
  }
  .bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
  }

  @media (min-width: 768px) {
	.bd-placeholder-img-lg {
	  font-size: 3.5rem;
	}
  }

  .nav_header-custom{
	border-bottom: 1px solid;
	box-shadow: 0px 0px 10px 10px #888888;	
  }