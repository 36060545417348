.nav_custom{
	border-bottom: 1px solid;
box-shadow: 0px 0px 10px 10px #888888;	
}

.nav-menuitem-custom{
text-decoration: none;
}
.nav-menuitem-custom:hover{
background-color: rgba(0, 0, 255, 0.89);
font-size: large;
font-weight: bold;
width:15vw;
text-align:center;
}
